<template>
  <div class="report-options flex flex-column">
    <p class="card-header-divider">Настройки сравнения групп</p>
    <div class="report-options__body">
      <custom-select-2
        label="Группа 1"
        name="report-group"
        v-model="group1"
        :options="groupOptions"
      ></custom-select-2>
      <custom-select-2
        label="Группа 2"
        name="report-group"
        v-model="group2"
        :options="groupOptions"
      ></custom-select-2>
    </div>
    <div
      v-if="group1 && group2"
      class="report-options__btn flex justify-content-center"
    >
      <button @click="requestForReport" class="btn btn-accent">
        Получить отчет
      </button>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, ref } from "@vue/runtime-core";
import CustomSelect2 from "../../../components/Forms/Fields/CustomSelect2.vue";
import { useStore } from "vuex";
import { notify } from "@kyvg/vue3-notification";
export default {
  components: { CustomSelect2 },
  name: "base-report-parametres",
  setup(props, context) {
    const store = useStore();
    const group1 = ref(null),
      group2 = ref(null),
      groupOptions = ref([]);
    const groups = computed(() => store.state.group.groups);

    onBeforeMount(() => {
      store.dispatch("group/getGroups").then(() => {
        groupOptions.value.push({ id: 0, text: "Все сотрудники"});
        groupOptions.value = groupOptions.value.concat(groups.value.map((group) => {
          return { id: group.id, text: group.name };
        }));
      });
    });

    const requestForReport = () => {
      if (group1.value === group2.value || !group1.value | !group2.value) {
        notify({
          type: "warning",
          title: "Выберите две разные группы",
        });
      } else {
        context.emit("reportRequest", {
          first_group_id: group1.value,
          second_group_id: group2.value,
        });
      }
    };

    return {
      group1,
      group2,
      groupOptions,
      groups,
      requestForReport,
    };
  },
};
</script>
