<template>
  <div v-if="baseData" class="base-report report flex flex-column top-divider">
    <div class="report-title">
      <span>Общая аналитика для одной группы респондентов</span>
      <button @click="print" class="btn btn-accent">Печать</button>
    </div>
    <div class="report-block">
      <div class="report-title lvl-2">
        1 Средние показатели по рассматриваемой группе
      </div>
      <div v-html="descriptions.average" class="report-description"></div>
      <div class="report-chart">
        <div class="flex flex-wrap justify-content-center report-chart-arrows">
          <arrow-chart
            v-for="(name, value) in baseData.average"
            :key="value"
            :scores="baseData.average[value]"
            :label="dictionary[value]"
          />
        </div>
        <consultation />
      </div>
    </div>
    <div class="report-block">
      <div class="report-title lvl-2">2 Показатели по общему ЭИ</div>
      <div
        class="
          pie-chart__content
          report-content
          flex flex-wrap
          justify-content-center
        "
      >
        <div v-html="descriptions.total" class="report-description"></div>
        <div class="report-chart">
          <pie-chart
            :chartLabels="Object.values(baseData.level_titles)"
            :chartData="pieTotalData"
            htmlLegend="base-pie-total"
            :color = "['#FF3131' ,'#FF65C3' ,'#8C52FF', '#004AAD' ,'#00BF62']"
          />
          <div class="chart-legend" id="base-pie-total"></div>
          <consultation />
        </div>
      </div>
    </div>
    <div class="report-block">
      <div class="report-title lvl-2">3 Показатели по доменам ЭИ</div>
      <div class="report-content flex flex-wrap justify-content-center">
        <div v-html="descriptions.domains" class="report-description"></div>
        <div class="report-chart">
          <bar-chart
            :chartLabels="levelDictionary"
            :chartData="barDomainsData"
            htmlLegend="base-bar-domains"
            :color = "['#C0FF72', '#CB6BE6']"
          />
          <div class="chart-legend" id="base-bar-domains"></div>
          <consultation />
        </div>
      </div>
    </div>
    <div class="report-block">
      <div class="report-title lvl-2">4 Соотношение доменов ЭИ</div>
      <div class="report-content flex flex-wrap justify-content-center">
        <div
          v-html="descriptions.dominateDomains"
          class="report-description"
        ></div>
        <div class="report-chart">
          <doughnut-chart
            :chartLabels="[
              'Опытный домен выше стратегического',
              'Стратегический домен выше опытного',
              'Нет доминирующего домена',
            ]"
            :chartData="[
              baseData.dominateDomains[0].count_dominate_domain_1,
              baseData.dominateDomains[0].count_dominate_domain_2,
              baseData.dominateDomains[0].count_without_dominate_domain,
            ]"
            htmlLegend="base-doughnut-dominate-domains"
          />
          <div class="chart-legend" id="base-doughnut-dominate-domains"></div>
          <consultation />
        </div>
        <div class="report-chart">
          <bar-chart
            :chartData="barDomaintDomains"
            :chartLabels="[
              'Опытный домен выше стратегического',
              'Стратегический домен выше опытного',
            ]"
            htmlLegend="base-bar-dominate-domains"
            :color = "['#C0FF72', '#CB6BE6']"
          />
          <div class="chart-legend" id="base-bar-dominate-domains"></div>
          <div class="flex justify-content-around people-count">
            <div class="people-count__element">
              <i class="fal fa-user"></i
              >{{ baseData.dominateDomains[0].count_dominate_domain_1 }}
            </div>
            <div class="people-count__element">
              <i class="fal fa-user"></i
              >{{ baseData.dominateDomains[0].count_dominate_domain_2 }}
            </div>
          </div>
          <consultation />
        </div>
      </div>
    </div>
    <div class="report-block">
      <div class="report-title lvl-2">5 Показатели по ветвям ЭИ</div>
      <div class="report-content flex flex-wrap justify-content-center">
        <div v-html="descriptions.branches" class="report-description"></div>
        <div class="report-chart">
          <bar-chart
            :chartLabels="levelDictionary"
            :chartData="branchBarStatistic"
            htmlLegend="base-bar-branch-statistic"
            :color = "['#7ED956', '#FFDE59', '#CC00CC', '#5271FF']"
          />
          <div class="chart-legend" id="base-bar-branch-statistic"></div>
          <consultation />
        </div>
      </div>
    </div>
    <div class="report-block">
      <div class="report-title lvl-2">6 Сильные стороны</div>
      <div class="report-content flex flex-wrap justify-content-center">
        <div
          v-html="descriptions.dominateBranches"
          class="report-description"
        ></div>
        <div class="report-chart">
          <pie-chart
            :chartLabels="dominateLabels"
            :chartData="dominatePieData"
            htmlLegend="base-pie-dominate-branches"
          />
          <div class="chart-legend" id="base-pie-dominate-branches"></div>
          <consultation />
        </div>
        <div class="report-chart">
          <bar-chart
            :chartData="dominateBarData"
            :chartLabels="dominateLabels"
            :legend="false"
          />
          <consultation />
          <!-- <div class="chart-legend" id="base-bar-dominate-branches"></div> -->
        </div>
        <div class="report-chart">
          <bar-chart
            :chartData="dominateBarRespondentsData"
            :chartLabels="levelDictionary"
            htmlLegend="base-bar-dominate-branches-respondents"
          />
          <div
            class="chart-legend"
            id="base-bar-dominate-branches-respondents"
          ></div>
          <consultation />
        </div>
      </div>
    </div>
    <div class="report-block">
      <div class="report-title lvl-2">7 Зоны потенциального роста</div>
      <div class="report-content flex flex-wrap justify-content-center">
        <div
          v-html="descriptions.growingBranches"
          class="report-description"
        ></div>
        <div class="report-chart">
          <pie-chart
            :chartLabels="growLabels"
            :chartData="growingPieData"
            htmlLegend="base-pie-growing-branches"
            :color = "['#FFDE59', '#5271FF', '#CC00CC', '#7ED956']"
          />
          <div class="chart-legend" id="base-pie-growing-branches"></div>
          <consultation />
        </div>
        <div class="report-chart">
          <bar-chart
            :chartData="growingBarData"
            :chartLabels="growLabels"
            :legend="false"
          />
          <consultation />
          <!-- <div class="chart-legend" id="base-bar-growing-branches"></div> -->
        </div>
        <div class="report-chart">
          <bar-chart
            :chartData="growingBarRespondentsData"
            :chartLabels="levelDictionary"
            htmlLegend="base-bar-growing-branches-respondents"
          />
          <div
            class="chart-legend"
            id="base-bar-growing-branches-respondents"
          ></div>
          <consultation />
        </div>
      </div>
    </div>
    <div v-if="baseData.harmonious.count.harmonious" class="report-block">
      <div class="report-title lvl-2">8 Гармоничные профили</div>
      <div class="report-content flex flex-wrap justify-content-center">
        <div v-html="descriptions.harmonious" class="report-description"></div>
        <div class="report-chart">
          <doughnut-chart
            :chartLabels="['Гармонические профили', 'Негармонические профили']"
            :chartData="[
              baseData.harmonious.count.harmonious,
              baseData.harmonious.count.inharmonious,
            ]"
            htmlLegend="base-pie-harmonic"
          />
          <div class="chart-legend" id="base-pie-harmonic"></div>
          <consultation />
        </div>
        <div class="report-chart">
          <bar-chart
            :chartLabels="levelDictionary"
            :chartData="harmonicBarData"
            htmlLegend="base-bar-harmonic"
          />
          <div class="chart-legend" id="base-bar-harmonic"></div>
          <consultation />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core";
import ArrowChart from "../charts/ArrowChart.vue";
import { useStore } from "vuex";
import PieChart from "../charts/PieChart.vue";
import BarChart from "../charts/BarChart.vue";
import DoughnutChart from "../charts/DoughnutChart.vue";
import useAnalyticsFunctions from "./../mixins/useAnalyticsFunctions";
import Consultation from "./../charts/details/Consultation.vue";
export default {
  components: { ArrowChart, PieChart, BarChart, DoughnutChart, Consultation },
  name: "base-report",
  setup() {
    const store = useStore();
    const {
      keysToLabels,
      barDataForAreas,
      barRespondentsData,
      checkScores,
      dictionary,
      levelDictionary,
    } = useAnalyticsFunctions();
    const baseData = computed(() => store.state.analytics.reportData),
      descriptions = computed(() => baseData.value.block_descriptions),
      pieTotalData = computed(() => {
        let newData = [];
        const total = baseData.value.total[0];
        checkScores(newData, total);
        return newData;
      }),
      barDomainsData = computed(() => {
        let newData = [
          {
            data: [],
            label: "Опытный домен",
          },
          {
            data: [],
            label: "Стратегический домен",
          },
        ];
        checkScores(newData[0].data, baseData.value.domains.domain_1);
        checkScores(newData[1].data, baseData.value.domains.domain_2);
        return newData;
      }),
      barDomaintDomains = computed(() => {
        let newData = [];
        newData.push({
          label: "Опытный домен, среднее",
          data: [
            baseData.value.dominateDomains[1].dominate_domain_1_average
              .domain_1,
            baseData.value.dominateDomains[1].dominate_domain_2_average
              .domain_1,
          ],
        });
        newData.push({
          label: "Стратегический домен, среднее",
          data: [
            baseData.value.dominateDomains[1].dominate_domain_1_average
              .domain_2,
            baseData.value.dominateDomains[1].dominate_domain_2_average
              .domain_2,
          ],
        });
        return newData;
      }),
      branchBarStatistic = computed(() => {
        let newData = [];
        for (let key in baseData.value.branches) {
          newData.push({ label: dictionary[key], data: [] });
          checkScores(
            newData[newData.length - 1].data,
            baseData.value.branches[key]
          );
        }
        return newData;
      }),
      dominateLabels = computed(() =>
        keysToLabels(Object.keys(baseData.value.dominateBranches.counts))
      ),
      growLabels = computed(() =>
        keysToLabels(Object.keys(baseData.value.growingBranches.counts))
      ),
      dominatePieData = computed(() =>
        pieDataForAreas(baseData.value.dominateBranches.counts)
      ),
      growingPieData = computed(() =>
        Object.values(baseData.value.growingBranches.counts)
      ),
      dominateBarData = computed(() =>
        barDataForAreas(baseData.value.dominateBranches.average)
      ),
      growingBarData = computed(() =>
        barDataForAreas(baseData.value.growingBranches.average)
      ),
      dominateBarRespondentsData = computed(() =>
        barRespondentsData(
          baseData.value.dominateBranches.respondentsDistributionByLevels
        )
      ),
      growingBarRespondentsData = computed(() =>
        barRespondentsData(
          baseData.value.growingBranches.respondentsDistributionByLevels
        )
      ),
      harmonicBarData = computed(() => {
        let newData = [
          {
            data: [],
            label: "Гармонические профили",
          },
        ];
        checkScores(newData[0].data, baseData.value.harmonious.levels);
        return newData;
      });

    const colors = {
      branch_1: '#7ED956', //Распознавание эмоций
      branch_2: '#FFDE59', //Использование эмоций
      branch_3: '#5271FF', //Анализ эмоций
      branch_4: '#CC00CC', //Управление эмоциями
    }

    const pieDataForAreas = (average) => {
      let newData = [];
      let maxLength = 1;
      const averageBranches = average;
      for (let branch in averageBranches) {
        Object.values(branch).length > 8 ? maxLength = 2 : ""
      }

      for (let i = 1; i <= maxLength; i++) {
        newData.push({ label: "", data: [], color: []});
        for (let branch in averageBranches) {

          let keys =[]
          keys.push(branch.split(','))

            if (Object.values(branch).length > 8) {
              let items = branch.split(',')
              const colorsNames = colors[items[0]] + ',' + colors[items[1]]
              newData[i - 1].data.push(averageBranches[branch])
              newData[i - 1].color.push(colorsNames)
            }

          else {
            newData[i - 1].data.push(averageBranches[branch])
            newData[i - 1].color.push(colors[keys[0][i-1]])
          }
        }
      }
      return newData;
    };

    const print = () => window.print();

    return {
      baseData,
      descriptions,
      pieTotalData,
      barDomainsData,
      barDomaintDomains,
      branchBarStatistic,
      keysToLabels,
      dominateLabels,
      growLabels,
      dominatePieData,
      dominateBarData,
      growingPieData,
      levelDictionary,
      dictionary,
      growingBarData,
      dominateBarRespondentsData,
      barDataForAreas,
      checkScores,
      harmonicBarData,
      barRespondentsData,
      growingBarRespondentsData,
      print,
    };
  },
};
</script>

<style lang="scss" scoped>
.people-count {
  font-size: 24px;
  width: 70%;
  margin: auto;
  padding-left: 22px;

  &__element {
    i {
      margin-right: 7px;
    }
  }

  &__element:first-child {
    padding-left: 20px;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
}

.report-chart-arrows .arrow:first-child {
  flex-basis: 100% !important;
}
</style>
