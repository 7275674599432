<template>
  <div
    class="arrow flex flex-column align-item-center col-50 mb-10"
  >
    <div class="arrow-container flex">
      <div class="arrow-line">
        <!-- 1 балл = 4 пикселя -->
        <span class="arrow-line__base-number" style="bottom: 280px">130</span
        ><span class="arrow-line__base-number" style="bottom: 200px">110</span
        ><span class="arrow-line__base-number" style="bottom: 160px">100</span
        ><span class="arrow-line__base-number" style="bottom: 120px">90</span
        ><span class="arrow-line__base-number" style="bottom: 40px">70</span>
        <span class="arrow-line__label" style="bottom: 270px"
          >Высокий уровень -
          <p>Экспертный</p></span
        ><span class="arrow-line__label" style="bottom: 215px"
          >Высокий уровень -
          <p>Профессиональный</p></span
        ><span class="arrow-line__label" style="bottom: 150px"
          >Средний уровень -
          <p>Средний</p></span
        ><span class="arrow-line__label" style="bottom: 80px"
          >Низкий уровень -
          <p>Начальный</p></span
        >
        <span class="arrow-line__label" style="bottom: 10px"
          >Низкий уровень -
          <p>Элементарный</p></span
        >

        <span
          :style="`bottom: ${scoreTop}px;`"
          :class="`arrow-line__result ${highlightColor}`"
          >{{ Math.round(scores) }} {{ scoreLabel }}
          <p>{{ label }}</p>
        </span>
      </div>
    </div>

    <div class="arrow-text chart-label">{{ longLabel }}</div>
  </div>
</template>

<script>
import { computed, ref, toRef } from "@vue/runtime-core";
export default {
  name: "arrow-chart",
  props: {
    scores: {
      type: Number,
      default: 150,
    },
    label: {
      type: String,
      default: "Общий ЭИ",
    },
  },
  setup(props) {
    const scoreCount = toRef(props, "scores"),
      labelDiagram = toRef(props, "label");
    const scoreLabel = computed(() => {
        switch (scoreCount.value % 10) {
          case 1:
            return "балл";
          case (2, 3, 4):
            return "балла";
          default:
            return "баллов";
        }
      }),
      scoreTop = computed(() =>
        scoreCount.value >= 60
          ? scoreCount.value >= 140
            ? 4 * scoreCount.value - 280
            : 4 * scoreCount.value - 254
          : 0
      ),
      longLabel = computed(() => {
        switch (labelDiagram.value) {
          case "Общий ЭИ":
            highlightColor.value = "common";
            return "Общие средние показатели";
          case "Опытный домен":
            highlightColor.value = "domen";
            return "Средние показатели по опытному домену";
          case "Стратегический домен":
            highlightColor.value = "domen";
            return "Средние показатели по стратегическому домену";
          case "Распознавание эмоций":
            return 'Средние показатели по ветке "Распознавание эмоций"';
          case "Использование эмоций":
            return 'Средние показатели по ветке "Использование эмоций"';
          case "Анализ эмоций":
            return 'Средние показатели по ветке "Анализ эмоций"';
          case "Управление эмоциями":
            return 'Средние показатели по ветке "Управление эмоциями"';
        }
      });
    const highlightColor = ref("branch");

    return {
      scoreLabel,
      scoreCount,
      scoreTop,
      labelDiagram,
      longLabel,
      highlightColor,
    };
  },
};
</script>


<style lang="scss" scoped>
.arrow-container {
  margin: 25px 190px 20px 131px;
  padding: 15px;

  .arrow-line {
    height: 350px;
    width: 5px;

    background-color: var(--chart-color);
    position: relative;

    &__base-number {
      display: inline-block;
      position: absolute;
      left: 15px;
      font-weight: 600;
    }

    &__base-number::before {
      position: absolute;
      content: "";
      width: 15px;
      height: 3px;
      background-color: var(--chart-color);
      top: 11px;
      left: -20px;
    }

    &__label {
      text-align: right;
      font-weight: 500;
      position: absolute;
      min-width: 220px;
      right: 20px;
      p {
        font-weight: 600;
        font-size: 17px;
      }
    }

    &__result {
      font-weight: 600;
      display: inline-block;
      position: absolute;
      left: 80px;
      font-size: 17px;
      p {
        font-size: 16px;
        min-width: 200px;
      }
    }

    &__result.branch {
      p {
        border-bottom: 3px solid #2c68c1;
      }
    }
    &__result.branch::before,
    &__result.branch::after {
      background-color: #2c68c1;
    }

    &__result.domen {
      p {
        border-bottom: 3px solid #c12cb3;
      }
    }
    &__result.domen::before,
    &__result.domen::after {
      background-color: #c12cb3;
    }

    &__result.common {
      p {
        border-bottom: 3px solid #2cb3c1;
      }
    }
    &__result.common::before,
    &__result.common::after {
      background-color: #2cb3c1;
    }

    &__result::before {
      position: absolute;
      content: "";
      width: 15px;
      height: 3px;
      background-color: var(--chart-line-color);
      top: 50%;
      left: -85px;
    }

    &__result::after {
      position: absolute;
      content: "";
      height: 2.5px;
      width: 75px;
      background-color: var(--chart-line-color);
      bottom: 12px;
      left: -73px;
      transform: rotate(18deg);
    }

    &::before {
      position: absolute;
      top: -22px;
      left: -5px;
      content: "";
      border-bottom: 22px solid var(--chart-color);
      border-left: 7.5px solid transparent;
      border-right: 7.5px solid transparent;
    }
  }
}
.arrow-text {
  font-weight: 500;
  margin-top: 25px;
  max-width: 260px;
  text-align: center;
  word-break: break-word;
}

@media (max-width: 1500px) {
  .arrow {
    flex-basis: 100% !important;
    overflow-x: auto;
  }

  .arrow-container {
    margin-left: 310px;

    .arrow-line {
      &__label,
      &__label p,
      &__base-number,
      &__result,
      &__result p {
        font-size: 14px;
      }

      &__result p {
        min-width: 170px;
      }

      &__result::after {
        bottom: 10.5px;
        left: -72px;
        transform: rotate(16deg);
        width: 74px;
      }
    }
  }
}

@media print {
  .arrow-container {
    -webkit-print-color-adjust: exact;
  }
}
</style>