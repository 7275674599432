<template>
  <div
    v-if="groupsData"
    class="flex flex-wrap justify-content-center w-full group-cards"
  >
    <group-card
      v-for="(value, key) in groupsData"
      :scalesStates="scalesStates(key)"
      :key="key"
      :name="key"
      :groupData="value"
    />
  </div>
</template>

<script>
import { toRef } from "@vue/reactivity";
import GroupCard from "./GroupCard.vue";
// import { computed } from '@vue/runtime-core'
export default {
  components: { GroupCard },
  name: "group-cards",
  props: {
    groupsData: {
      type: Object,
    },
  },
  setup(props) {
    const groups = toRef(props, "groupsData");
    const dictionary = [
      "total",
      "domain_1",
      "domain_2",
      "branch_1",
      "branch_2",
      "branch_3",
      "branch_4",
    ];

    const scalesStates = (group) => {
      const stateObject = new Array(7).fill("", 0, 7);

      dictionary.forEach((name, index) => {
        stateObject[index] = isSame(name, groups.value[group][name]);
        if(stateObject[index] == "") stateObject[index] = isHigher(name, groups.value[group][name])
      });

      return stateObject;
    };
    const isSame = (key, value) => {
      let sameCheck = true;
      for (let group in groups.value) {
        if (groups.value[group][key] != value) sameCheck = false;
      }
      if (sameCheck) return "same" 
      else return "";
    };
    const isHigher = (key, value) => {
      let higherCheck = true;
      for (let group in groups.value) {
        if (groups.value[group][key] > value) higherCheck = false;
      }
      if(higherCheck) return "higher" 
      else return  "";
    };

    return {
      groups,
      scalesStates,
      dictionary,
      isSame,
      isHigher,
    };
  },
};
</script>

<style lang="scss" scoped>
.group-cards {
  gap: 10px;
  div {
    flex-basis: 48%;
  }

  @media (max-width: 1290px) {
    div {
      flex-basis: 100%;
    }
  }
}
</style>