
export default function () {

	let pos = { top: 0, left: 0, x: 0, y: 0 };

	const mouseDownHandler = function (e) {
		e.currentTarget.style.cursor = 'grabbing';
		e.currentTarget.style.userSelect = 'none';
		pos = {
			left: e.currentTarget.scrollLeft,
			x: e.clientX,
		};

		e.currentTarget.addEventListener('mousemove', mouseMoveHandler);
		e.currentTarget.addEventListener('mouseup', mouseUpHandler);
		e.currentTarget.addEventListener('mouseleave', mouseUpHandler);
	};

	const mouseMoveHandler = function (e) {
		const dx = e.clientX - pos.x;

		e.currentTarget.scrollLeft = pos.left - dx;
	};

	const mouseUpHandler = function (e) {
		e.currentTarget.removeEventListener('mousemove', mouseMoveHandler);
		e.currentTarget.removeEventListener('mouseup', mouseUpHandler);
		e.currentTarget.removeEventListener('mouseleave', mouseUpHandler);

		e.currentTarget.style.cursor = 'default';
		e.currentTarget.style.removeProperty('user-select');
	};

	return { mouseDownHandler }

}