<template>
  <div
    class="score-scale flex justify-content-between align-item-center w-full"
  >
    <div class="score-scale__title">{{ label }}</div>
    <div :class="`score-scale__scale scale ${state}`">
      <div class="scale__number">{{ score }}</div>
      <div class="scale__body">
        <div
          role="progressbar"
          :aria-valuenow="score"
          :aria-valuemin="0"
          :aria-valuemax="150"
          class="scale__progress"
          :style="`width: ${(score / 150) * 100}%;`"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "score-scale",
  props: {
    label: {
      type: String,
    },
    score: {
      type: Number,
      default: 0,
    },
    state: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.score-scale {
  font-size: 16px;
}

.scale {
  display: flex;
  align-items: center;
  padding: 8px;
  border: 2.5px solid transparent;
  border-radius: 7px;

  &__number {
    margin-right: 10px;
    font-weight: 600;
  }

  &__body {
    position: relative;
    background-color: var(--cream-color);
    width: 200px;
    height: 20px;
    border-radius: 7px;
  }

  &__progress {
    background-color: var(--main-color);
    height: 20px;
    border-radius: 7px;
  }

  &.higher {
    border-color: #9b09ff;
  }

  &.same {
    border-color: #fdc30d;
  }
}

@media (max-width: 950px) {
  .score-scale {
    font-size: 14px;
  }
}

@media (max-width: 900px) {
  .scale {
    &__body {
      display: none;
    }

    &__number {
      margin-right: 0;
    }
  }
}
</style>