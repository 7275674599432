<template>
  <div class="reference-user-report report flex flex-column top-divider">
    <div class="report-title">
      Поиск респондентов, соответствующих эталонному профилю
    </div>
    <table class="table-sm support-table">
      <tr>
        <td class="user-type-accord">Соответствуют эталонному профилю</td>
        <td class="user-type-accord_range">
          В диапазоне соответствия эталонному профилю
        </td>
        <td class="user-type-not_accord">
          Не соответствуют эталонному профилю
        </td>
      </tr>
    </table>
    <hr />
    <div class="report-block">
      <div @mousedown="mouseDownHandler" class="table-responsive">
        <table class="table-sm w-full">
          <thead>
            <tr>
              <th style="width: 420px">Имя</th>
              <th>Общий ЭИ</th>
              <th>Домен 1</th>
              <th>Домен 2</th>
              <th>Ветвь 1</th>
              <th>Ветвь 2</th>
              <th>Ветвь 3</th>
              <th>Ветвь 4</th>
              <th>Секция 1</th>
              <th>Секция 2</th>
              <th>Секция 3</th>
              <th>Секция 4</th>
              <th>Секция 5</th>
              <th>Секция 6</th>
              <th>Секция 7</th>
              <th>Секция 8</th>
              <th>Секция 9а</th>
              <th>Секция 9б</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="user in reportData.users"
              :key="user.id"
              :class="['user-type-' + user.type]"
            >
              <td>{{ user.name }}</td>
              <td>{{ user.total }}</td>
              <td>{{ user.domain_1 }}</td>
              <td>{{ user.domain_2 }}</td>
              <td>{{ user.branch_1 }}</td>
              <td>{{ user.branch_2 }}</td>
              <td>{{ user.branch_3 }}</td>
              <td>{{ user.branch_4 }}</td>
              <td>{{ user.section_1 }}</td>
              <td>{{ user.section_2 }}</td>
              <td>{{ user.section_3 }}</td>
              <td>{{ user.section_4 }}</td>
              <td>{{ user.section_5 }}</td>
              <td>{{ user.section_6 }}</td>
              <td>{{ user.section_7 }}</td>
              <td>{{ user.section_8 }}</td>
              <td>{{ user.section_9a }}</td>
              <td>{{ user.section_9b }}</td>
              <td>
                <router-link
                  :to="`/staff/${user.id}/edit`"
                  class="btn btn-accent sm-row__btn"
                  >Перейти
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core";
import { useStore } from "vuex";
import useDraggableOverflow from "@/mixins/useDraggableOverflow";

export default {
  name: "reference-users-report",
  props: {
    groupId: {
      type: String,
    },
  },
  setup() {
    const store = useStore();
    const reportData = computed(() => store.state.analytics.reportData);
    const { mouseDownHandler } = useDraggableOverflow();

    return {
      reportData,
      mouseDownHandler,
    };
  },
};
</script>

<style lang="scss" scoped>
.reference-user-report {
  .support-table {
    margin-bottom: 25px;
    font-weight: 50;
  }

  .user-type-accord {
    background: #beffe0;
  }

  .user-type-accord_range {
    background: #fff1c5;
  }

  .user-type-not_accord {
    background: #ffd2d4;
  }
}

.sm-row {
  &__btn {
    min-width: 138px;
    margin-left: 15px;
  }
}
</style>
